<template>
    <v-container fluid class="pa-6">
        <DatatableV2 :displayTitle="false" :flat="false" :url="apiUrl"/>
    </v-container>
</template>
<script>
import DatatableV2 from '@/components/DatatableV2'
export default {
    name:"EntityList",
    components: {
        DatatableV2
    },
    data() {
        return {
            apiUrl:"/entity/list",
        }
    },
    
}
</script>
<style lang="scss">

</style>
